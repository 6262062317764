import ElementsPage from "@/pages/ElementsPage.vue";
import NewProjectPage from "@/pages/NewProjectPage.vue";
import MainPage from "@/pages/MainPage.vue";
import EntrepreneursPage from "@/pages/EntrepreneursPage.vue";
import AreaPage from "@/pages/AreaPage.vue";
import ApartmentPage from "@/pages/ApartmentPage.vue";
import ContentPage from "@/pages/ContentPage.vue";
import ApartmentsComparePage from "@/pages/ApartmentsComparePage.vue";
import ScrollGalleryPage from "@/pages/ScrollGalleryPage.vue";
import FavoriteApartments from "@/pages/FavoriteApartments.vue";
import FloorPage from "@/pages/FloorPage.vue";
import FloorsPage from "@/pages/FloorsPage.vue";

import PrivacyAgreement from "@/components/PrivacyAgreement.vue"
import ModelApartmentsPage from "@/model-pages/ModelApartmentsPage.vue";
import TabsContentPage from "@/pages/TabsContentPage.vue";
import i18n, {defaultLocale, languages, setI18nLanguage} from "@/i18n";
import store from "@/store";

function validateLanguageRoute(to, _, next) {
    const lang = to.params.lang || defaultLocale; // Default to 'en' if no lang is specified
    store.commit("locale", lang);
    setI18nLanguage(i18n, lang);
    console.log({
        lang
    })

    if (!languages[lang]) {
        // Redirect to the same route with the default language
        const routePath = `/${defaultLocale}${to.fullPath.startsWith('/') ? to.fullPath : '/' + to.fullPath}`;
        return next({
            path: routePath,
            replace: true,
        });
    }

    next(); // Proceed as normal if language is valid
}


export default [
    {
        path: "/",
        redirect: `/${defaultLocale}/`,
    },
    {
        path: "/:lang([a-z]{2})?",
        name: 'home',
        component: MainPage,
        beforeEnter: validateLanguageRoute,
    },
    {
        path: '/view-360',
        name: 'view-360',
        component: MainPage,
    },
    {
        path: '/elements',
        component: ElementsPage,
    },
    {
        path: '/content/:postName',
        name: 'content',
        component: ContentPage,
        beforeEnter: validateLanguageRoute,
    },
    {
        path: "/:lang([a-z]{2})?/projects/:projectId",
        name: 'projects',
        component: NewProjectPage,
        beforeEnter: validateLanguageRoute,
    },
    {
        path: "/:lang([a-z]{2})?/projects/:projectId/apartments",
        name: 'apartments',
        component: ModelApartmentsPage,
        beforeEnter: validateLanguageRoute,
    },
    {
        path: "/:lang([a-z]{2})?/projects/:projectId/apartments-compare",
        name: 'apartments-compare',
        component: ApartmentsComparePage,
        beforeEnter: validateLanguageRoute,
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/apartments/:apartmentId',
        name: 'apartment',
        component: ApartmentPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/apartments/:apartmentId/ao',
        name: 'apartment-only',
        component: ApartmentPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/floors',
        name: 'floors',
        component: FloorsPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/floors/:floorId',
        name: 'floor',
        component: FloorPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/entrepreneurs-architects',
        component: EntrepreneursPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/favorites',
        name: 'favorite-apartments',
        component: FavoriteApartments
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/:postId',
        component: EntrepreneursPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/content/:postId',
        component: EntrepreneursPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/tcontent/:postId',
        component: TabsContentPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/scroll-gallery/:postId',
        component: ScrollGalleryPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/area',
        component: AreaPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/:projectId/area/:postId',
        name: 'area-page',
        component: AreaPage
    },
    {
        path: '/:lang([a-z]{2})?/projects/privacy',
        name: 'privacy',
        component: PrivacyAgreement
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
];
